import styled, { css } from 'styled-components';

const logoStyles = css`
  .logoDot {
    fill: #020203;
  }
  .logoDotBg,
  .logoT,
  .logoE,
  .logoA,
  .logoL,
  .logoA {
    fill: #ff8332;
  }
`;

const fixedLogoStyles = css`
  .logoDot {
    fill: #fff;
  }
  .logoDotBg,
  .logoT,
  .logoE,
  .logoA,
  .logoL,
  .logoA {
    fill: #fff;
  }
`;

export const Desktop = styled('div')`
  display: none;
  path {
    transition: fill 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  ${({ scrollDirection, scrolledToTop }) =>
    !scrollDirection && !scrolledToTop
      ? css`
          ${fixedLogoStyles};
        `
      : css`
          ${logoStyles};
        `}
  ${({ theme }) => theme.mq.xl} {
    display: block;
  }
`;

export const Mobile = styled('div')`
  display: block;
  path {
    transition: fill 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  ${({ scrollDirection, scrolledToTop }) =>
    !scrollDirection && !scrolledToTop
      ? css`
          ${fixedLogoStyles};
        `
      : css`
          ${logoStyles};
        `}
  ${({ theme }) => theme.mq.xl} {
    display: none;
  }
`;
