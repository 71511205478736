import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { useIntl } from 'gatsby-plugin-intl';
import useStickyState from 'hooks/useStickyState';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import withLocale from 'hoc/withLocale';

import {
  StyledBar,
  StyledEuFlag,
  StyledEuImage,
  StyledTextWrapper,
  StyledLink,
  StyledText,
  StyledAccept,
} from './CookieBarStyles';

const CookieBar = ({ currentLocale }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "eu-logos" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  const image = getImage(data.file);

  const location = useLocation();
  useEffect(() => {
    initializeAndTrack(location.hostname);
  }, []);

  const [bannerHidden, setBannerHidden] = useStickyState(
    false,
    'consentCookieHidden'
  );

  const handleAcceptCookies = () => {
    document.cookie = 'gatsby-gdpr-google-tagmanager=true';
    setBannerHidden(true);
  };

  return (
    <>
      {!bannerHidden && (
        <StyledBar>
          <StyledEuFlag>
            {currentLocale === 'pl' ? (
              <Link to={`/${currentLocale}/projekty-ue`}>
                <StyledEuImage image={image} alt="EU" />
              </Link>
            ) : (
              <Link to={`/${currentLocale}/eu-projects`}>
                <StyledEuImage image={image} alt="EU" />
              </Link>
            )}
          </StyledEuFlag>

          <StyledTextWrapper>
            <StyledText>
              {intl.formatMessage({ id: 'private_policy.text' })}{' '}
              <StyledLink
                to={`/${currentLocale}/${intl.formatMessage({
                  id: 'private_policy.link.url',
                })}`}
              >
                {intl.formatMessage({ id: 'private_policy.link.label' })}
              </StyledLink>
              .
            </StyledText>
            <StyledAccept onClick={handleAcceptCookies}>OK</StyledAccept>
          </StyledTextWrapper>
        </StyledBar>
      )}
    </>
  );
};

export default withLocale(CookieBar);
