import React from 'react';
import { theme } from '../../../theme/theme';

const IconLinkedin = ({ black = false, small = false }) => (
  <svg
    width={small ? '18' : '24'}
    height={small ? '18' : '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99417 23V8.15664H1.27824V23H5.99417ZM3.63682 6.12881C5.28134 6.12881 6.30499 4.98901 6.30499 3.56465C6.27435 2.10816 5.28141 1 3.66802 1C2.05489 1 1 2.10818 1 3.56465C1 4.98908 2.02339 6.12881 3.60603 6.12881H3.63667H3.63682ZM8.60443 23H13.3204V14.7107C13.3204 14.2671 13.351 13.8239 13.4755 13.5068C13.8165 12.6205 14.5924 11.7024 15.8952 11.7024C17.6017 11.7024 18.2844 13.0636 18.2844 15.059V22.9999H23V14.4888C23 9.92955 20.6734 7.80816 17.5706 7.80816C15.0266 7.80816 13.9095 9.29584 13.289 10.3091H13.3205V8.15633H8.60453C8.66642 9.54915 8.60453 22.9997 8.60453 22.9997L8.60443 23Z"
      fill={black ? theme.colors.textBrown : theme.colors.white}
    />
  </svg>
);

export default IconLinkedin;
