import React from 'react';

const IconClose = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="20.2495"
      y1="20"
      x2="2.10042"
      y2="1.85093"
      stroke="black"
      strokeWidth="2.33333"
      strokeLinecap="round"
    />
    <line
      x1="1.16667"
      y1="-1.16667"
      x2="26.8333"
      y2="-1.16667"
      transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 0.463257 20.0039)"
      stroke="black"
      strokeWidth="2.33333"
      strokeLinecap="round"
    />
  </svg>
);

export default IconClose;
