import React, { useRef, useEffect } from 'react';
import useWindowSize from 'hooks/useWindowSize';
import {
  StyledScrollWrapper,
  StyledScrollContainer,
} from './ScrollWrapperStyles';

const ScrollWrapper = ({ children }) => {
  const size = useWindowSize();
  const page = useRef(null);
  const scrollContainer = useRef(null);

  const smoothConfigs = {
    ease: 0.1,
    current: 0,
    previous: 0,
    smooth: 0,
  };

  const smoothScrolling = () => {
    smoothConfigs.current = window.scrollY;
    smoothConfigs.previous +=
      (smoothConfigs.current - smoothConfigs.previous) * smoothConfigs.ease;
    smoothConfigs.smooth = Math.round(smoothConfigs.previous * 100) / 100;
    if (scrollContainer.current) {
      scrollContainer.current.style.transform = `translateY(-${smoothConfigs.smooth}px)`;
    }

    window.requestAnimationFrame(() => smoothScrolling());
  };

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const scrollContainer = document.querySelector('.scroll-wrapper');
    setTimeout(() => {
      document.body.style.height = `${
        scrollContainer.getBoundingClientRect().height
      }px`;
    }, 1000);
  }, [size]);

  useEffect(() => {
    window.requestAnimationFrame(() => smoothScrolling());
  }, []);

  return (
    <StyledScrollWrapper ref={page}>
      <StyledScrollContainer ref={scrollContainer} className="scroll-wrapper">
        {children}
      </StyledScrollContainer>
    </StyledScrollWrapper>
  );
};

export default ScrollWrapper;
