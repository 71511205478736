export const colors = {
  white: 'hsl(0, 0%, 100%)',
  lightGray: '#969696',
  veryLightGray: '#e6e6e6',
  gray: 'hsl(0, 0%, 90%)',
  black: 'hsla(0, 0%, 18%, 1)',
  lightOrange: 'hsl(24, 100%, 79%)',
  orange: 'hsl(24, 100%, 60%)',
  lightGreen: 'hsla(107, 40%, 70%, 1)',
  darkGreen: 'hsla(109, 65%, 30%, 1)',
  red: 'hsl(358, 100%, 67%)',
  primary: '#dddddd',
  textGray: '#565656',
  textBrown: '#8a786a',
  footerBg: '#b7b7b7',
};

export const breakpoints = {
  xs: 320,
  sm: 400,
  smm: 500,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1800,
  xxxxl: 2200,
};

export const mq = Object.keys(breakpoints).reduce((acc, breakpoint) => {
  acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`;
  return acc;
}, {});

export const theme = {
  colors,
  mq,
};
