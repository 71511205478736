import React from 'react';

const IconLogo = () => (
  <svg
    width="165"
    height="20"
    viewBox="0 0 165 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.69829 2.23542H0V0.620514H17.1928V2.23542H9.49456V19.8752H7.69829V2.23542Z"
      fill="#FF8332"
    />
    <path
      d="M36.9519 0.744858H52.6051V2.35976H38.7482V8.69517H49.0126V10.1859H38.7482V18.2604H52.6051V19.8753H36.9519V0.744858Z"
      fill="#FF8332"
    />
    <path
      d="M81.3453 0L92.3795 20H90.4549L87.7605 15.1553H74.6734L71.979 20H69.9261L81.3453 0ZM86.9907 13.6646L81.3453 3.22981L75.5716 13.6646H86.9907Z"
      fill="#FF8332"
    />
    <path
      d="M111.369 0.744858H113.165V18.3846H125.739V19.9995H111.369V0.744858Z"
      fill="#FF8332"
    />
    <path
      d="M153.837 0L165 20H163.075L160.381 15.1553H147.294L144.599 20H142.546L153.837 0ZM159.611 13.6646L153.966 3.22981L148.192 13.6646H159.611Z"
      fill="#FF8332"
    />
  </svg>
);

export default IconLogo;
