import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { Desktop, Mobile } from './StyledLogo';

const LogoWrapper = styled.div`
  width: 4rem;
  height: 100%;
  display: grid;
  place-items: center;
  ${({ theme }) => theme.mq.xl} {
    width: 6rem;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

const Logo = props => {
  const localData = useStaticQuery(graphql`
    {
      logo: file(relativeDirectory: { eq: "logo" }, name: { eq: "logo" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <Desktop>
        <LogoWrapper width={props.width}>
          <img src={localData.logo.childImageSharp.fluid.src} alt="logo" />
        </LogoWrapper>
      </Desktop>
      <Mobile>
        <LogoWrapper width={props.width}>
          <img src={localData.logo.childImageSharp.fluid.src} alt="logo" />
        </LogoWrapper>
      </Mobile>
    </>
  );
};

export default Logo;
