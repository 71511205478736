import { createGlobalStyle } from 'styled-components';
import '../vendors/normalize.css';
import './scrollbar.css';
import './swiper.css';

const GlobalStyle = createGlobalStyle`

  html,
  body {
    height: 100%;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-size: 1.6rem;
    font-family: 'Gothic', sans-serif;
    overflow-x: hidden;
    overscroll-behavior-y: none;
  }
  
  h1, h2, h3, h4, h5, p, span {
    margin: 0;
    font-family: 'Gothic', sans-serif;
  }

  h2 {
    font-weight: 600;
  }
  
  button {
    font-family: 'Gothic', sans-serif;
    padding: 0;
    background: none;
    outline: none;
    border: 0;
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }
  
  ul {
    padding: 0;
    margin: 0;
  }

  ::selection {
    background-color: #D6CBED;
    color: #fff;
  }
`;

export default GlobalStyle;
