import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

export const StyledSelectLanguageList = styled('div')`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
`;

export const StyledSelectLanguageItem = styled('a')`
  cursor: pointer;
  font-weight: 400;
  margin: 0 1rem;
  color: ${({ theme }) => theme.colors.black};
  ${({ selected }) =>
    selected &&
    css`
      font-weight: 600;
      border-bottom: 1px solid #000000;
    `}
`;

export const StyledLinkToRelatedPage = styled(Link)`
  cursor: pointer;
  font-weight: 400;
  margin: 0 1rem;
  color: ${({ theme }) => theme.colors.black};
  ${({ selected }) =>
    selected &&
    css`
      font-weight: 600;
      border-bottom: 1px solid #000000;
    `}
`;
