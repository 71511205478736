import React from 'react';

const IconShop = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.8466 25.4106V48.744"
      stroke="#FF595E"
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.1534 25.4106V48.744"
      stroke="#FF595E"
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.1846 48.741V38.3717C33.1846 35.5087 30.863 33.187 28 33.187C25.137 33.187 22.8153 35.5087 22.8153 38.3717V48.741"
      stroke="#FF595E"
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66675 48.7438H51.3334"
      stroke="#FF595E"
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0358 25.4079C6.07376 25.3799 3.49076 21.4576 5.20809 18.0766L9.20976 10.1969C10.1221 8.40028 12.0401 7.25928 14.1448 7.25928H41.8554C43.9601 7.25928 45.8781 8.40028 46.7904 10.1969L50.7944 18.0766C52.5118 21.4576 49.9288 25.3799 45.9668 25.4079C42.6604 25.4079 39.9794 23.1493 39.9794 20.3609V20.2489C39.9794 23.0979 37.2984 25.4079 33.9921 25.4079C30.6858 25.4079 28.0048 23.0979 28.0048 20.2489C28.0048 23.0979 25.3238 25.4079 22.0174 25.4079C18.7111 25.4079 16.0301 23.0979 16.0301 20.2489V20.3609C16.0231 23.1493 13.3421 25.4079 10.0358 25.4079Z"
      stroke="#FF595E"
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconShop;
