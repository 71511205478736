import styled, { css } from 'styled-components';

export const StyledSocialList = styled('ul')`
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1.6rem;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      justify-content: space-between;
      width: 100%;
      ${({ theme }) => theme.mq.md} {
        justify-content: flex-end;
      }
    `}
`;

export const StyledSocialItem = styled('li')`
  list-style: none;
  width: ${({ small }) => (small ? '3rem' : '5rem')};
  height: ${({ small }) => (small ? '3rem' : '5rem')};
  display: grid;
  place-items: center;
  border: 1px solid
    ${({ theme, black }) =>
      black ? theme.colors.textBrown : theme.colors.white};
  border-radius: ${({ small }) => (small ? '0.8rem' : '1.5rem')};
`;
