import React from 'react';
import PropTypes from 'prop-types';
import {
  IconInstagram,
  IconLinkedin,
  IconYoutube,
  IconFacebook,
  IconClose,
  IconLogo,
  IconShop,
  IconEuro
} from 'components/atoms/icons';

const Icon = ({ name, black = false, small = false }) => {
  switch (name) {
    case 'Instagram':
      return <IconInstagram black={black} small={small} />;
    case 'Linkedin':
      return <IconLinkedin black={black} small={small} />;
    case 'Facebook':
      return <IconFacebook black={black} small={small} />;
    case 'Youtube':
      return <IconYoutube black={black} small={small} />;
    case 'Euro':
      return <IconEuro black={black} small={small} />;
    case 'Close':
      return <IconClose />;
    case 'Logo':
      return <IconLogo />;
    case 'Shop':
      return <IconShop />;
    default:
      return null;
  }
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
